<template>
  <div>
    <h4>{{ i18n['profile-non-member-basic-info'].tcBasicInfo }}</h4>
    <div class="specs">
      <p>
        <strong>{{ i18n['profile-non-member-basic-info'].tcRelationshipType }}:</strong>
        {{ profile.relationship_type_name }}
      </p>
      <p>
        <strong>{{ i18n['profile-non-member-basic-info'].tcTitle }}:</strong>
        {{ profile.title }}
      </p>
      <p>
        <strong>{{ i18n['profile-non-member-basic-info'].tcRelatedOrganization }}:</strong>
        <br />
        <a href="#" style="underline" @click.prevent="locationStoreKeyAndRedirect(profile.related_organization_key)">{{
          profile.related_organization
        }}</a>
      </p>
      <p>
        <strong>{{ i18n['profile-non-member-basic-info'].tcBirthdate }}:</strong>
        <br />
        {{ profile.birthdate ? profile.birthdate.substring(0, 10) : '' }}
      </p>
      <p>
        <strong>{{ i18n['profile-non-member-basic-info'].tcOtherInformation }}:</strong>
        <br />
        {{ profile.comment }}
      </p>
      <router-link :to="{ name: 'non-member-details' }" class="btn btn-primary">
        {{i18n['profile-non-member-basic-info'].tcViewMore}}
      </router-link>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'profile-non-member-basic-info',
  mixins: [translationMixin],
  props: {
    profile: {
      relationship_type_name: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      related_organization: {
        type: String,
        default: '',
      },
      related_organization_chu_key: {
        type: String,
        default: '',
      },
      birthdate: {
        type: String,
        default: '',
      },
      comment: {
        type: String,
        default: '',
      },
    },
  },
  data() {
    return {
      i18n: { 'profile-non-member-basic-info': {} },
    }
  },
  async created() {
    this.i18n = await this.getComponentTranslations('profile-non-member-basic-info')
  },
  methods: {
    ...mapActions({
      setSelectedLocationKey: 'user/setSelectedLocationKey',
      setSelectedChurchKey: 'user/setSelectedChurchKey',
      setSelectedFacilityKey: 'user/setSelectedFacilityKey',
    }),
    async locationStoreKeyAndRedirect(data) {
      let path = this.$route.path.split('/')
      switch (path[path.length-1]) {
        case 'non-member-profile-gc':
          await this.setSelectedFacilityKey(data)
          this.$router.push({ name: 'card_profile' })
          break;
        case 'non-member-profile-cm':
          await this.setSelectedChurchKey(data)
          this.$router.push({ name: 'church-profile' })
          break;
        case 'non-member-profile-sd':
          await this.setSelectedLocationKey(data)
          this.$router.push({ name: 'location-profile' })
          break;
        default:
          //non-member-profile-mb
          if (this.profile.related_organization_chu_key) {
            await this.setSelectedChurchKey(data)
            this.$router.push({ name: 'church-profile' })
          } else {
            await this.setSelectedLocationKey(data)
            this.$router.push({ name: 'location-profile' })
          }
          break;
      }
    },
  },
  computed: {
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';
</style>
